import React, { useState } from "react";
import { Box, Grid, Dialog } from "@mui/material";
import Slider from "react-slick";
import cabaña1 from "../imagenes/cabañas/cabaña1.jpg";
import cabaña2 from "../imagenes/cabañas/cabaña2.jpg";
import cabaña3 from "../imagenes/cabañas/cabaña3.jpg";
import cabaña4 from "../imagenes/cabañas/cabaña4.jpg";
import cabaña5 from "../imagenes/cabañas/cabaña5.jpg";
import cabaña6 from "../imagenes/cabañas/cabaña6.jpg";
import cabaña7 from "../imagenes/cabañas/cabaña7.jpg";
import cabaña8 from "../imagenes/cabañas/cabaña8.jpg";
import cabaña9 from "../imagenes/cabañas/cabaña9.jpg";
import cabaña10 from "../imagenes/cabañas/cabaña10.jpg";
import cabaña11 from "../imagenes/cabañas/cabaña11.jpg";
import cabaña12 from "../imagenes/cabañas/cabaña12.jpg";
import camino from "../imagenes/cabañas/camino.jpg";
import chicosjugando from "../imagenes/cabañas/chicosjugando.jpg";
import puente from "../imagenes/cabañas/puente.jpg";
import espacio1 from "../imagenes/espacios/calistenia1.jpg"
import espacio2 from "../imagenes/espacios/calistenia2.jpg"
import espacio3 from "../imagenes/espacios/futbol.jpg"
import espacio4 from "../imagenes/espacios/pingpong.jpg"
import espacio5 from "../imagenes/espacios/pool.jpg"
import jueguitos from "../imagenes/cabañas/jueguitos.jpeg"
import lagoyverde from "../imagenes/cabañas/lagoyverde.jpeg"

const GaleriaFotos = () => {
  // Lista de imágenes para la galería
  const galleryItems = [
    { src: cabaña10, alt: "Imagen 10" },
    { src: cabaña11, alt: "Imagen 11" },
    { src: cabaña12, alt: "Imagen 1" },
    { src: cabaña1, alt: "Imagen 1" },
    { src: cabaña2, alt: "Imagen 2" },
    { src: cabaña3, alt: "Imagen 3" },
    { src: cabaña4, alt: "Imagen 4" },
    { src: cabaña5, alt: "Imagen 5" },
    { src: cabaña6, alt: "Imagen 6" },
    { src: cabaña7, alt: "Imagen 7" },
    { src: cabaña8, alt: "Imagen 8" },
    { src: jueguitos, alt: "Imagen 9" },
    { src: lagoyverde, alt: "Imagen 10" },
    { src: espacio3, alt: "Imagen 11" },
    { src: espacio4, alt: "Imagen 12" },
    { src: espacio5, alt: "Imagen 13" },
    { src: cabaña9, alt: "Imagen 14" },
    { src: camino, alt: "Imagen 15" },
    { src: chicosjugando, alt: "Imagen 16" },
    { src: puente, alt: "Imagen 17" },
    { src: espacio2, alt: "Imagen 18" }
    
  ];

  // Estado para controlar la imagen seleccionada en pantalla completa
  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  // Función para abrir el diálogo con la imagen seleccionada
  const handleOpen = (image) => {
    setSelectedImage(image);
    setOpen(true);
  };

  // Función para cerrar el diálogo
  const handleClose = () => {
    setOpen(false);
    setSelectedImage(null);
  };

  return (
    <Box
      sx={{
        mt: 5,
        px: 2,
        maxWidth: "1200px",
        margin: "0 auto",
      }}
    >
      <Grid container spacing={0.5}>
        {galleryItems.map((item, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Box
              sx={{
                overflow: "hidden",
                borderRadius: "15px",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                cursor: "pointer",
              }}
              onClick={() => handleOpen(item.src)}
            >
              <img
                src={item.src}
                alt={item.alt}
                style={{
                  width: "100%",
                  height: "210px",
                  objectFit: "cover",
                }}
              />
            </Box>
          </Grid>
        ))}
      </Grid>

      {/* Diálogo para mostrar la imagen en pantalla completa */}
      <Dialog open={open} onClose={handleClose} maxWidth="lg">
        {selectedImage && (
          <img
            src={selectedImage}
            alt="Imagen ampliada"
            style={{
              width: "100%",
              height: "auto",
              maxHeight: "90vh",
              objectFit: "contain",
            }}
          />
        )}
      </Dialog>
    </Box>
  );
};

export default GaleriaFotos;
