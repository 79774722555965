import React, { useEffect } from 'react';
import { AppBar, Toolbar, Typography, IconButton, Box } from '@mui/material';
import { Cabin, WhatsApp as WhatsAppIcon } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import '../css/encabezado.css'; // Importamos el archivo CSS

function Encabezado() {
  useEffect(() => {
    const header = document.getElementById('header');
    if (header) {
      const headerHeight = header.offsetHeight;
      document.body.style.marginTop = `${headerHeight}px`;
    }
  }, []);

  return (
    <AppBar
      id="header"
      position="fixed"
      sx={{
        bgcolor: 'transparent',
        background: 'linear-gradient(90deg, #6a9c78, #6a9c78)',
        color: 'white',
        boxShadow: 8,
      }}
    >
      <Toolbar
        className="header-toolbar" // Clase para control responsivo
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          textAlign: 'center',
          gap: 1,
          padding: { xs: '10px', sm: '15px 100px' },
        }}
      >
        {/* Sección izquierda */}
        <Box className="header-icons" sx={{ display: 'flex', gap: 3,}}>
          <Box
            component={Link}
            to="/nuestro-complejo"
            sx={{
              textDecoration: 'none',
              color: 'inherit',
              display: 'flex',
              flexDirection: 'column', // Ícono arriba del título
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <IconButton color="inherit" sx={{ '&:hover': { transform: 'scale(1.1)', transition: '0.2s' }, mb: -0.7,
              }}>
              <Cabin sx={{fontSize: { xs: '1.5rem', sm: '1.8rem' }
            }}/>
            </IconButton>
            <Typography
              variant="caption"
              sx={{
                fontSize: { xs: '0.7rem', sm: '0.8rem' },
                fontWeight: '700',
                textTransform: 'uppercase',
                letterSpacing: '0.15em', // Espaciado moderno
                fontFamily: 'Playfair Display, serif',
                
              }}
            >
              Nuestras Cabañas
            </Typography>
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <IconButton
              color="inherit"
              href="https://wa.me/3512021498"
              target="_blank"
              sx={{ '&:hover': { transform: 'scale(1.1)', transition: '0.2s' }, mb: -0.7 }}
            >
              <WhatsAppIcon sx={{fontSize: { xs: '1.5rem', sm: '1.8rem' }
            }}/>
            </IconButton>
            <Typography
              variant="caption"
              component="a" // Esto convierte el Typography en un enlace
              href="https://wa.me/3512021498"
              target="_blank"
              sx={{
                fontSize: { xs: '0.7rem', sm: '0.8rem' },
                fontWeight: '700',
                textTransform: 'uppercase',
                letterSpacing: '0.17em', // Espaciado moderno
                fontFamily: 'Playfair Display, serif',
                textDecoration: 'none',
                color: 'inherit',
              }}
            >
              Reservar
            </Typography>
          </Box>
        </Box>

            {/* Centro: Nombre */}
            <Box
      className="header-logo"
      component={Link}
      to="/"
      sx={{
        marginY: { xs: 1, sm: 0 },
        marginLeft: { sm: '10%', md: '-15%' }, // Ajusta el margen izquierdo solo en escritorio
        display: 'flex',
        flexDirection: 'column',
        alignItems: { xs: 'center', sm: 'flex-start' }, // Centra en móvil, ajusta en escritorio
        justifyContent: 'center',
        textDecoration: 'none',
        color: 'inherit',
        
      }}>
        
      <Typography
        variant="h6"
        sx={{
          fontSize: { xs: '1.2rem', sm: '1.6rem' },
          fontFamily: 'Playfair Display, serif',
          fontWeight: '400',
          letterSpacing: '0.15em',
          mb: -1,
          ml: 7.5
        }}
      >
        Flor Serrana
      </Typography>
      <Typography
        variant="h4"
        sx={{
          fontSize: { xs: '1rem', sm: '1.3rem' },
          fontFamily: 'Playfair Display, serif',
          fontWeight: '700',
          textTransform: 'uppercase',
          letterSpacing: '0.13em'
        }}
      >
        Complejo Turístico
      </Typography>
    </Box>

        {/* Bandera */}
        <Box>
          <img
            src="https://flagcdn.com/ar.svg"
            alt="Idioma"
            className="bandera"
              />
          
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default Encabezado;
