import React from 'react';
import { Box, Typography, Grid } from '@mui/material';
import BotonFlotante from '../componentes/BotonFlotante';
import Carrusel from '../componentes/Carrusel';
import pileta from '../imagenes/pileta.jpg';
import lago from '../imagenes/lago.jpg';
import piletas from '../imagenes/piletas.jpg';
import lagito from '../imagenes/lagito.jpg';
import rincon1 from '../imagenes/rincon1.jpeg';
import rincon3 from '../imagenes/rincon3.jpg';
import rincon4 from '../imagenes/rincon4.jpg';
import Juegos1 from '../imagenes/Juegos1.JPG';

const Principal = () => {
  return (
    <div>
      {/* Carrusel */}
      <Box sx={{ width: '100%', overflowX: 'hidden', mt: { xs: 9, md: 0 }, mb: { xs: -68, md: 0}}}>
        <Carrusel />
      </Box>

      {/* Sección: Naturaleza */}
      <Box sx={{ py: { xs: 3, md: 5,}, textAlign: 'center', px: { xs: 2, md: 5 } }}>
        <Typography
          variant="h4"
          sx={{
            fontFamily: "'Poppins', sans-serif",
            fontWeight: 700,
            color: "#444444",
            mb: { xs: 1, md: 1 },
            fontSize: { xs: '1.5rem', md: '2.5rem' },
          }}
        >
          Tu contacto con la naturaleza
        </Typography>

        {/* Botón estilo Instagram */}
        <Box sx={{ display: 'flex', justifyContent: 'center', mb: -2 }}>
          <a
            href="https://www.instagram.com/complejo_florserrana/"
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: 'none' }}
          >
            <Box
              sx={{
                display: 'inline-block',
                padding: { xs: '8px 15px', md: '10px 20px' },
                border: '2px solid white',
                borderRadius: '8px',
                backgroundColor: '#4CAF50',
                color: 'white',
                fontWeight: 'bold',
                textTransform: 'uppercase',
                fontSize: { xs: '0.8rem', md: '1rem' },
                transition: 'background-color 0.3s, transform 0.2s',
                '&:hover': {
                  backgroundColor: '#45a049',
                  transform: 'scale(1.05)',
                },
              }}
            >
              Conocé el Complejo
            </Box>
          </a>
        </Box>
      </Box>

      {/* Sección: Paraíso Refrescante */}
      <Box sx={{ bgcolor: '#f9f6f0', py: { xs: 2, md: 5 }, px: { xs: 2, md: 5 } }}>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} md={6}>
            <Typography
              variant="h5"
              sx={{
                fontFamily: "'Poppins', sans-serif",
                fontWeight: 700,
                color: "#444444",
                mb: { xs: 1, md: 2 },
                fontSize: { xs: '1.3rem', md: '2rem' },
              }}
            >
              Paraíso refrescante
            </Typography>
            <Typography
              sx={{
                fontFamily: "'Roboto', sans-serif",
                fontWeight: 400,
                fontSize: { xs: '0.9rem', md: '1.3rem' },
                lineHeight: 1.6,
                textAlign: "justify",
                color: "#555555",
              }}
            >
              Contamos con tres piletas exclusivas, diseñadas para todas las edades y
              preferencias, desde espacios ideales para el descanso hasta áreas
              perfectas para divertirte con amigos y toda la familia.
            </Typography>
          </Grid>
          <Grid item xs={10} md={6} >
            <Box display="flex" flexWrap="wrap" gap={1} justifyContent="center" >
              <img src={pileta} alt="Pileta" style={{ width: '48%', borderRadius: '8px' }} />
              <img src={Juegos1} alt="Juegos" style={{ width: '48%', borderRadius: '8px' }} />
              <img src={piletas} alt="Piletas" style={{ width: '48%', borderRadius: '8px' }} />
              <img src={lagito} alt="Lagito" style={{ width: '48%', borderRadius: '8px' }} />
            </Box>
          </Grid>
        </Grid>
      </Box>

      {/* Sección: Diversión asegurada */}
      <Box sx={{ bgcolor: '#fff', py: { xs: 3, md: 5 }, px: { xs: 2, md: 5 } }}>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} md={6}>
            <img src={lago} alt="Lago" style={{ width: '100%', borderRadius: '8px' }} />
          </Grid>
          <Grid item xs={10} md={6}>
            <Typography
              variant="h4"
              sx={{
                fontFamily: "'Poppins', sans-serif",
                fontWeight: 700,
                fontSize: { xs: '1.5rem', md: '2rem' },
                color: "#333333",
                mb: 2,
              }}
            >
              Diversión asegurada para toda la familia
            </Typography>
            <Typography
              sx={{
                fontFamily: "'Roboto', sans-serif",
                fontWeight: 300,
                fontSize: { xs: '0.9rem', md: '1.3rem' },
                lineHeight: 1.6,
                textAlign: "justify",
                color: "#555555",
              }}
            >
              Contamos con una variedad de juegos para que disfrutes al máximo de tu
              estadía. Podés disfrutar de una cancha de bochas, una cancha de vóley,
              mesas de ping pong y pool.
            </Typography>
          </Grid>
        </Grid>
      </Box>

      {/* Rincones Naturales */}
      <Box sx={{ bgcolor: '#f9f6f0', py: { xs: 3, md: 5 }, px: { xs: 2, md: 5 } }}>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} md={6}>
            <Typography
              variant="h4"
              sx={{
                fontFamily: "'Poppins', sans-serif",
                fontWeight: 700,
                fontSize: { xs: '1.5rem', md: '2rem' },
                color: "#333333",
                mb: 2,
              }}
            >
              Rincones naturales
            </Typography>
            <Typography
              sx={{
                fontFamily: "'Roboto', sans-serif",
                fontWeight: 300,
                fontSize: { xs: '0.9rem', md: '1.3rem' },
                lineHeight: 1.6,
                textAlign: "justify",
                color: "#555555",
              }}
            >
              Nuestro complejo está lleno de rincones especiales, ideales para disfrutar de un
              buen mate y una charla en grupo. Rodeados de naturaleza, encontrarás espacios
              tranquilos y acogedores.
            </Typography>
          </Grid>
          <Grid item xs={10} md={6} img xs={10}>
            <Box display="flex" flexWrap="wrap" gap={2} justifyContent="center" flexDirection={{ xs: 'row', md: 'row' }}>
              <img src={rincon1} alt="Rincones" style={{ width: '32%', borderRadius: '8px' }} />
              <img src={rincon3} alt="Rincones" style={{ width: '32%', borderRadius: '8px' }} />
              <img src={rincon4} alt="Rincones" style={{ width: '32%', borderRadius: '8px' }} />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default Principal;
