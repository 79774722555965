// src/paginas/NuestroComplejo.js
import React from 'react';
import { Box, Typography, Grid, Paper, List, ListItem, ListItemText, Button } from '@mui/material';
import Servicios from '../componentes/Servicios'
import GaleriaFotos from '../componentes/GaleriaFotos';


function NuestroComplejo() {
  // Configuración de Slick Carousel
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  return (
    <div>
      <Box sx={{ padding: 4 }}>
        <Typography variant="h2" sx={{   fontFamily: "'Roboto', sans-serif",
                fontWeight: 400,
                fontSize: "2.5rem",
                lineHeight: 1.8,
                textAlign: "center",
                color: "#555555", // Gris medio
                textTransform: 'uppercase',
                mb: 0
          }}>
         Nuestras Cabañas
        </Typography>
        <Typography variant="body1" sx={{   fontFamily: "'Roboto', sans-serif",
                fontWeight: 400,
                fontSize: "1.1rem",
                lineHeight: 1.8,
                textAlign: "center",
                color: "#555555", // Gris medio
                }}>
        Contamos con 17 cabañas completamente equipadas, entre las cuales tenemos capacidad de 6 personas hasta 2 personas. Cada cabaña posee asador y cochera propia.
        
        </Typography>
        <Typography variant="body1" sx={{ 
            fontFamily: "'Roboto', sans-serif",
            fontWeight: 400,
            fontSize: "1.1rem",
            lineHeight: 1.8,
            textAlign: "center",
            color: "#555555", // Gris medio
        }}>
        En Flor Serrana tenemos 6 hectáreas de parque, con vistas a Carlos Paz y Cosquín para que disfrutes del verde y la tranquilidad de las sierras del Valle de Punilla.  
      
        </Typography>

        <Servicios />

       <Typography variant="h4" sx={{ mt: 5, mb: 1,
              fontFamily: "'Roboto', sans-serif",
              fontWeight: 400,
              fontSize: "2rem",
              lineHeight: 1.8,
              textAlign: "center",
              color: "#555555", // Gris medio
              textTransform: 'uppercase',
          }}>
           Galeria de fotos
          </Typography>

          <GaleriaFotos />
          <Box
          sx={{
            mt: 4,
            display: 'flex',
            justifyContent: 'center',
            gap: 2,
          }}
        >
          <Button
            variant="contained"
            color="primary"
            href="https://www.youtube.com/watch?v=LNgMRfyschg&t=143s"
            target="_blank"
            rel="noopener noreferrer"
          >
            Las cabañas por dentro
          </Button>
          <Button
            variant="contained"
            color="primary"
            href="https://www.youtube.com/watch?v=VE1j8MeHTlY&t=81s"
            target="_blank"
            rel="noopener noreferrer"
          >
            Nuestros espacios
          </Button>
          <Button
            variant="contained"
            color="primary"
            href="https://www.youtube.com/watch?v=QO32ysVVVa8&t=3s"
            target="_blank"
            rel="noopener noreferrer"
          >
            Aves que nos visitan
          </Button>
        </Box>

      </Box>
    </div>
  );
}

export default NuestroComplejo;
